@font-face {
  font-family: 'pp_woodlandbold';
  src: url('../fonts/woodland/ppwoodland-bold-webfont.woff2') format('woff2'),
  url('../fonts/woodland/ppwoodland-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 21, 2019 */

@font-face {
  font-family: 'objective';
  src: url('../fonts/objective/objective-bold-italic-webfont.woff2') format('woff2'),
  url('../fonts/objective/objective-bold-italic-webfont.woff') format('woff'),
  url('../fonts/objective/objective-bold-italic-webfont.ttf') format('truetype'),
  url('../fonts/objective/objective-bold-italic-webfont.svg#objectivebold_italic') format('svg');
  font-weight: 600;
  font-style: italic;

}

@font-face {
  font-family: 'objective';
  src: url('../fonts/objective/objective-bold-webfont.woff2') format('woff2'),
  url('../fonts/objective/objective-bold-webfont.woff') format('woff'),
  url('../fonts/objective/objective-bold-webfont.ttf') format('truetype'),
  url('../fonts/objective/objective-bold-webfont.svg#objectivebold') format('svg');
  font-weight: 600;
  font-style: normal;

}

@font-face {
  font-family: 'objective';
  src: url('../fonts/objective/objective-italic-webfont.woff2') format('woff2'),
  url('../fonts/objective/objective-italic-webfont.woff') format('woff'),
  url('../fonts/objective/objective-italic-webfont.ttf') format('truetype'),
  url('../fonts/objective/objective-italic-webfont.svg#objectiveitalic') format('svg');
  font-weight: 300;
  font-style: italic;

}

@font-face {
  font-family: 'objective';
  src: url('../fonts/objective/objective-medium-italic-webfont.woff2') format('woff2'),
  url('../fonts/objective/objective-medium-italic-webfont.woff') format('woff'),
  url('../fonts/objective/objective-medium-italic-webfont.ttf') format('truetype'),
  url('../fonts/objective/objective-medium-italic-webfont.svg#objectivemedium_italic') format('svg');
  font-weight: 500;
  font-style: italic;

}

@font-face {
  font-family: 'objective';
  src: url('../fonts/objective/objective-medium-webfont.woff2') format('woff2'),
  url('../fonts/objective/objective-medium-webfont.woff') format('woff'),
  url('../fonts/objective/objective-medium-webfont.ttf') format('truetype'),
  url('../fonts/objective/objective-medium-webfont.svg#objectivemedium') format('svg');
  font-weight: 500;
  font-style: normal;

}

@font-face {
  font-family: 'objective';
  src: url('../fonts/objective/objective-regular-webfont.woff2') format('woff2'),
  url('../fonts/objective/objective-regular-webfont.woff') format('woff'),
  url('../fonts/objective/objective-regular-webfont.ttf') format('truetype'),
  url('../fonts/objective/objective-regular-webfont.svg#objectiveregular') format('svg');
  font-weight: 300;
  font-style: normal;

}

@font-face {
  font-family: 'icons';
  src: url('../fonts/icons/icons.eot?vgcfsx');
  src: url('../fonts/icons/icons.eot?vgcfsx#iefix') format('embedded-opentype'),
  url('../fonts/icons/icons.ttf?vgcfsx') format('truetype'),
  url('../fonts/icons/icons.woff?vgcfsx') format('woff'),
  url('../fonts/icons/icons.svg?vgcfsx#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"], .icon-font {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
  content: "\e900";
}

.icon-location:before {
  content: "\e901";
}

/*============================================================================
  TYPOGRAPHY
==============================================================================*/

// Font Stacks
@mixin default-font($size: 17, $weight: 300, $lineHeight: 1.5em) {
  font-family: 'objective', Helvetica, Arial, Verdana, sans-serif;
  @include fs($size);
  line-height: $lineHeight;
  font-weight: $weight;
}

@mixin display-font() {
  font-family: 'pp_woodlandbold', Museo Sans, Helvetica, Arial, Verdana, sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  @include optimize-font();
}

.display-font {

  @include display-font;
  @include lh(28);
}

.display-link {

  @include display-font;
  color: $black;
  text-decoration: underline;

}

// Body

p {
  line-height: 1.5em;
  margin-bottom: 1.5em;
}

strong {
  font-weight: 600;
  font-size: inherit;
  color: #808080;
  @include display-font;
}

em {
  font-style: italic;
  font-weight: inherit;
}

a {
  //&:not(.unstyled) {
  // @include display-font;

  font-weight: inherit;
  font-style: inherit;
  color: $linkColor;
  transition: $transitionDefault;
  // text-decoration: underline;

  text-decoration: none;

  text-decoration-skip: ink;
  // @include smart-underline; cross browser text-decoration skip

  &:hover, &:focus {
    color: $linkColorHover;
  }

  &:focus, &:active {
    outline: 0;
    //}
  }

  &.tdn {
    text-decoration: none;
    &:hover, &:focus {
      text-decoration: underline;
    }
  }
}

a[href^=tel] {
  /*text-decoration:none;
  &:hover, &:focus {
    text-decoration: underline;
  }*/
}

a:not([href]) {
  color: $black;
  text-decoration: none;
  &:hover, &:focus {
  }
}

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
}

// Headings

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  @include display-font;
  @include optimize-font;
  color: $accent;
  line-height: 1.2em;
  margin: 0 0 0.8em;

  a {
    text-decoration: none;
    outline: 0;
    font-weight: inherit;
    color: $accent;

    &:hover, &:focus {
      color: $accentHover;
    }
  }
}

h1, .h1 {
  @include fluid-type(font-size, $mediumSize, $largerSize, 25px, 50px);
}

h2, .h2 {
  @include fluid-type(font-size, $mediumSize, $largerSize, 18px, 20px);
}

h3, .h3 {
  @include fluid-type(font-size, $mediumSize, $largeSize, 16px, 18px);
}

h4, .h4 {
  @include fs(16);
}

h5, .h5 {
}

h6, .h6 {
}

// Lists

ul, ol {
  margin: 0 0 1.5em 16px;

  li {
    line-height: 1.5em;
    padding: 0;
  }
}

ul ul, ul ol, ol ol, ol ul {
  margin-bottom: 0;
}

ul {
  list-style: disc;

  ul {
    list-style: circle;
  }

  &.lined {
    @extend .unstyled;
    li {
      border-bottom: 1px solid #E3E3E3;
      padding: 5px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
}

ol {
  list-style: decimal;
}

.unstyled, nav ul {
  margin: 0 0 1.5em;
  padding: 0;
  list-style: none;

  ul {
    list-style: none;
  }

  li {
    list-style: none;
    list-style-image: none;
    padding: 0;
    background: none;
  }
}

nav ul {
  @include clearfix;
}

dl {
  margin: 0 0 1.5em;
}

dt, dd {
  line-height: 1.5em;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5em;
}

// Misc

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $gray;
  margin: 50px 0;
}

blockquote {
  line-height: inherit;
  padding-left: 20px;
  border-left: 1px solid $gray;
  margin-bottom: 1.5em;
  @include fs(20);
  font-style: italic;

  p {
    margin-bottom: 0.5em;
  }

  cite {
    @include fs(14);
  }

  @media #{$medium} {
    padding-left: 40px;
  }
}
