
/*============================================================================
  FORMS
==============================================================================*/

form {
  margin: 0;
}

legend {
  border: 0; padding: 0; white-space: normal; *margin-left: -7px;
  font-size: 18px;
}

button, input, select, textarea, .button {
  margin: 0; vertical-align: baseline; *vertical-align: middle;
  transition: $transitionDefault;
}

button, input {
  line-height: normal;
}

button, select {
  text-transform: none;
}

// When having a body font-size < 16px
// Prevent zoom on touch devices in active inputs
// @media #{$maxMedium} {
//   input, textarea {
//     font-size: 16px;
//   }
// }

label, .label, input, select, textarea {
  @include default-font();
  vertical-align: middle; color: $black;
}


label, .label {
  display: block; margin-bottom: 8px;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;  cursor: pointer; *overflow: visible; border: none;
}

input[type="checkbox"], input[type="radio"] {
  cursor: pointer;
}

input[type="text"], input[type="search"], input[type="password"],
input[type="email"], input[type="tel"], input[type="date"], textarea, select {
  padding: 6px; margin-bottom: 2px; display: block;
  background: #fff; height: 35px; line-height: inherit; width: 100%;
  border:none; @include fs(14);
  border-bottom: 1px solid $black;
  &:focus { outline: 0; border-color: $grayDark; }
}

input[type="email"] {
  display: block;
}

textarea {
  overflow: auto; vertical-align: top; resize: vertical; width: 100%; height: 185px;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0; padding: 0;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box; width: auto; height: auto; padding: 0;
  margin: 3px 0; *margin-top: 0; *height: 13px; *width: 13px; line-height: normal; border: none;
}

input[type="file"] {
  background-color: #fff; padding: initial; border: initial; line-height: initial;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

// Buttons
input[type="reset"], input[type="submit"], .button {
  float: left; display: block; clear: both;
  transition: $transitionDefault;
  outline: 0; line-height: 1.2em;
   margin: 10px 0 0; background-color: $accent;
  text-decoration: none; color: #fff;
  text-transform: lowercase;
  font-weight: 500;
  @include fs(14);
  position: relative;

  padding: 15px 25px;

  @media #{$medium} {
    padding: 20px 30px;
    padding-right: 100px;

  }

  @media #{$larger} {
    padding-right: 150px;
  }

  i { margin: -5px 6px 0 5px; }

  &:hover, &:focus, &:active {
    color: $white;
   //background-color: $accentHover;

    &:before {
      opacity: 1;
      right: 20px;
      //transform: translate(60px, -50%);

    }
  }

  &:active {
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.2);
    transform: translateY(1px);
  }

  &::-moz-focus-inner {
    border: 0;
  }


  &:before {

    @extend .icon-font;

    content: "\e900";
   @include fs(6);
    display: inline-block;
    //background-color: $white;
    position: absolute;
    top: 50%;
    right: 100px;
    transform: translateY(-50%);
    transition: $transitionDefault;
    opacity: 0;
  }
}

button, input[type="text"], input[type="submit"], input[type="button"], input[type="reset"],
input[type="email"], input[type="tel"], textarea {
  -webkit-appearance: none;
}

button, input[type="reset"], input[type="submit"], .button, a.button, select {
  &:focus {
    outline: 0; *outline: inherit;
    box-shadow: 0 0 1px 1px rgba(200,200,200,0.1);
  }
}

::placeholder { color: $black; font-style: normal; }

// Hide clear text field ie 10+
::-ms-clear { display: none; }

// Remove the rounded corners
input[type="search"] { -webkit-appearance: none; }

// Hide the cancel button
::-webkit-search-cancel-button { -webkit-appearance: none; }

// Hide the magnifying glass
::-webkit-search-results-button { -webkit-appearance: none; }

::-webkit-textfield-decoration-container { }

// Hide number input spinner
::-webkit-inner-spin-button { -webkit-appearance: none; }
::-webkit-outer-spin-button { -webkit-appearance: none; }

// Style file upload button
::-ms-browse, ::-webkit-file-upload-button { }

.checkbox, .radio {
  position: relative;

  label {
    padding-left: 35px;
    cursor: pointer;
    @include fs(14);

    &:before, &:after {
      content: "";
      display: block;
      position: absolute;
      transform: $transitionDefault;
    }

    &:before {
      left: 0; top: 0;
      width: 20px; height: 20px;
      border: 1px solid $gray;
    }

    &:after {
      top: 2px; left: 6px;
      border: solid $black;
      border-width: 0 3px 3px 0;
      width: 8px; height: 13px;
      transform: rotate(45deg);
      opacity: 0;
    }
  }

  input[type="checkbox"], input[type="radio"] {
    position: absolute; top: 0; left: 0;
    z-index: -1;
    visibility: hidden;

    &:checked + label {
      &:before {
        border-color: $grayDark;
      }

      &:after {
        opacity: 1;
      }
    }
  }
}

.radio {
  label {
    &:before, &:after {
      border-radius: 100%;
    }

    &:after {
      border: 0;
      transform: none;
      top: 4px; left: 4px;
      width: 12px; height: 12px;
      background-color: $black;
    }
  }
}

// Forms
form {
  .row {
    margin-bottom: 0;
  }
}

fieldset {
  width: auto;

  p, ul, li {
    @include clearfix;
  }

  ul {
    margin-left: -20px;

    li {
      list-style: none; background: none; list-style-image: none;
      margin: 0 0 10px; float: left; width: 100%; padding: 0 0 0 20px;

      &.error {
        input {
          border-color: $error;
        }
      }

      &.space {
        margin-top: 25px;
      }

      @media #{$medium} {
        &.one-half { width: 50%; }
        &.one-fourth { width: 25%; }
      }
    }
  }
}

.buttons {
  @include clearfix;
  button, input[type="reset"], input[type="submit"], .button, a.button {
    float: left; clear: none; margin-right: 15px;

    &.right {
      float: right;
      margin-right: 0;
      margin-left: 15px;
    }
  }
}

.a-c {
  .button, a.button, button {
    float: none; clear: none; display: inline-block;
  }
}

// Recaptcha
.grecaptcha-badge { display: none; }

.floating-label-container {
  position: relative;
  display: inline-block;vertical-align: top;
  width: 100%;

  label {
    @include fs(14);
    position: absolute;
    top:0; left: 0; transform: translate(0,0);
    white-space: nowrap; width: auto;
    opacity: 0; visibility: hidden;
    transition: all 0.5s;

    &.active {
      display: block; visibility: visible; opacity: 1; @include fs(12);
      transform:translate(0.5em, 0.5em);


    }
  }

  textarea {
    border-radius: 0;
    padding-top: 25px;
  }

  input {
    height: 55px;
    border-radius: 0;


    &.active {
      padding-top: 20px;
    }
  }

  &.floating-label-container--above {
    margin-top: 1.4em;
    label.active { transform:translate(0em, -2em);  }
    input { height: 35px; &.active { padding-top: 6px;}}
  }

  &.floating-label-container--error {
    input {
      border: 1px solid red;
    }

  }

}
