
/*============================================================================
  RESET

  Adapted version of:
  - Eric Meyer reset
  - Boilerplate Normalize.css
  - Normalize opentype
==============================================================================*/

html {
  overflow-y: scroll;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html, body {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, cite, code, del,
dfn, em, img, q, s, samp, small, strike, sub, sup, tt, var, dd, dl, dt, li, ol, ul, fieldset,
form, label, legend, button, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0; padding: 0; border: 0;
  font-weight: inherit; font-style: inherit; font-size: 100%; line-height: 1; font-family: inherit;
}

article, aside, details, figcaption, figure, footer, header, hgroup, nav, time, section, main {
  display: block;
}

*, *:before, *:after {
  box-sizing: border-box;
}

// Display in IE9
audio, canvas, video {
  display: inline-block;
}

// Prevents modern browsers from displaying 'audio' without controls
audio:not([controls]) { display: none; }


// Type
a:focus { outline: thin dotted; }

ol, ul { list-style: none; }
q:before, q:after, blockquote:before, blockquote:after { content: ""; }

// Prevents sub and sup affecting line-height in all browsers
sub, sup {
  font-size: 75%; line-height: 0; position: relative; vertical-align: baseline;
}

// Turn on proper supercript numerals
sup {
  top: -0.5em;
}

// Turn on proper subscript numerals
sub {
  bottom: -0.25em;
}

figure { margin: 0; }
img { border: 0; -ms-interpolation-mode: bicubic; }
svg:not(:root) { overflow: hidden; }

// Turn on small caps for upper and lowercase letters
abbr {
  text-transform: uppercase;
}

time {
  display: block;
}

// Forms
button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

button, input {
  line-height: normal;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0; padding: 0;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer; -webkit-appearance: button;
}

input[type="search"] { // Appearance in Safari/Chrome
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; // Inner-padding issues in Chrome OSX, Safari 5
}

textarea {
  overflow: auto; // Remove vertical scrollbar in IE6-9
  vertical-align: top; // Readability and alignment cross-browser
}
