/**
 * OVERVIEW
 *
 * IMPORTS
 * reset, vars, mixins, core, plugins
 *
 * LAYOUT
 * Helpers
 * Header / Main / Footer
 * Components
 * Pages (page overrides)
 *
 * Hooks (IE, ...)
 * Print
 */
/**
 * GUIDELINES
 * CLASSES
 * Naming: BEM - http://getbem.com/
 * Abbreviations: Emmet - http://docs.emmet.io/cheat-sheet/ | example: .va-m
 *
 * MEDIAQUERIES
 * @media $small, $medium, $maxMedium, $large, $larger, $huge, $massive
 */
/* =============================================================================
   IMPORTS
   ========================================================================== */
// CSS Reset
@import "inc/reset";
// Core
@import "inc/vars";
@import "inc/mixins";
//@import "inc/animations";
//@import "inc/custom-animations";
// Styled patterns and elements
@import "inc/grid";
@import "inc/type";
@import "inc/tables";
@import "inc/forms";
// Plugins
@import "plugins/validation-engine";
//@import "plugins/fancybox";

/* =============================================================================
   LAYOUT
   ========================================================================== */

html {
  font-size: 62.5%;
}

body {
  @include default-font;
  //color: $black;
  color: $bodyColor;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

.page-wrap {
  position: relative;

  @media #{$medium} {
    min-height: 100vh;
    z-index: 2;
  }
}

.container {
  position: relative;
  max-width: 1480px;
  margin: 0 auto;
  padding: 0 20px;

  @media #{$large} {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.container--small {

  max-width: 1000px;

}
.container--large {
  max-width: 1800px;
  padding: 0;
  @media #{$massive} {
    padding-left: 40px;
    padding-right: 40px;
  }
}

// Typekit async loading (all elements using $ typekit font)
.wf-loading {
  overflow: hidden;
  body {
    visibility: hidden;
  }
}

::selection {
  background-color: $selection;
  color: inherit;
}

.cc-banner {
  text-align: center;
}

/* =============================================================================
   HELPERS
   ========================================================================== */

// Icons
.icon {
  display: inline-block;
  vertical-align: middle;
}

.icon-hamburger, .icon-hamburger:before, .icon-hamburger:after {
  width: 24px;
  position: absolute;
  left: 0;
  height: 1px;
  border-top: 3px solid $accent;
  transition: $transitionDefault;
}

.icon-hamburger {
  position: relative;
  &:before, &:after {
    content: "";
    margin-left: 0;
  }
  &:before {
    margin-top: 3px;
  }
  &:after {
    margin-top: 9px;
  }
}

.w100 {
  width: 100%;
}

.db.db {
  display: block;
}

// Exceptions: only add what you need!
.mb0.mb0 {
  margin-bottom: 0;
}

.mb-gutter.mb-gutter {
  margin-bottom: $gutter;
}

.mb-gutter-x2.mb-gutter-x2 {
  margin-bottom: $gutter;
  @media #{$medium} {
  margin-bottom: $gutter*2;
  }
}

.mb-gutter-x3.mb-gutter-x3 {
  margin-bottom: $gutter;
  @media #{$medium} {
    margin-bottom: $gutter*3;
  }
}

.mb-gutter-x4.mb-gutter-x4 {
  margin-bottom: $gutter*4;
}

.mt-gutter.mt-gutter {
  margin-top: $gutter;
}

.mt-gutter-x2.mt-gutter-x2 {
  margin-top: $gutter*2;
}



.mt-gutter-x4.mt-gutter-x4 {
  margin-top: $gutter*4;
}

.mb-spacer.mb-spacer {
  margin-bottom: 25px;
  @media #{$medium} {
    margin-bottom: 50px;
  }
}

.mb-spacer-medium.mb-spacer-medium {
  margin-bottom: 50px;
  @media #{$medium} {
    margin-bottom: 100px;
  }
}

.mb-spacer-large.mb-spacer-large {
  margin-bottom: 30px;
  @media #{$medium} {
    margin-bottom: 150px;
  }
}
.mobile--mb-gutter.mobile--mb-gutter {
  @media #{$maxMedium} {
    margin-bottom: $gutter;
  }
}

// .mb20.mb20 { margin-bottom: 20px; }
// .mb40.mb40 { margin-bottom: 40px; }

// .mt0.mt0 { margin-top: 0; }
// .mt20.mt20 { margin-top: 20px; }
// .mt40.mt40 { margin-top: 40px; }

// .ttu { text-transform: uppercase; }
// .ttl { text-transform: lowercase; }

// Google maps mw
.gm-style img {
  max-width: inherit;
}

// Responsive video
.video-container {
  position: relative;
  padding: 0 0 56.25%;
  height: 0;
  overflow: hidden;
  margin: 0 0 15px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// Equal height
.equal-h {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;


  > * {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;

    * {
      max-width: 100%;
    }
  }

  &:before, &:after {
    display: none;
  }
}

html.no-flexbox {
  .equal-h {
    height: 100%;
    > div {
      float: left;
    }
  }
}

// Vertical align (not recommended)
// .va-wrap, .va-m, .va-b { display: table; width: 100%; height: 100%; }
// .va-wrap { table-layout: fixed; }
// .va-m, .va-b { display: table-cell; }
// .va-m { vertical-align: middle; }
// .va-b { vertical-align: bottom; }

// Block link
.block-link {
  position: relative;
  cursor: pointer;
}

.block-link__target {
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.large--pr-one-twelfth {
  @media #{$large} {
    padding-right: 8.333%;
    display: block;

  }
}

/* =============================================================================
   HEADER
   ========================================================================== */

.container--paper-header {
  height: 100px;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;

  @media #{$medium} {
    max-width: 50vw;
    height: 390px;
  }

  @media #{$larger} {
    //height: 950px;

   // height: 425px;

  }


}
.pt-paper-header {

  @media #{$medium} {
    padding-top: 160px;
  }

  @media #{$larger} {
    padding-top: 155px;
  }

}
body.home .container--paper-header {
  @media #{$medium} {
    height: 840px;
  }

  @media #{$larger} {
    height: 940px;
  }
}

.paper-header {
  background-image: url('../images/paper-bg-header.jpg');
  background-size: cover;
  height: 0;
  width: 100%;
  @media #{$medium} {
    height: 100%;
  }

}
.page-header {
  @include clearfix;
  padding: 20px 0;

  @media #{$medium} {
    padding-top: 130px;
    padding-bottom: 30px;
  }
}

.page-header__logo {
  text-decoration: none;
  @include opacity(100);
  float: left;
  position: relative;
  z-index: 9999;
  @media #{$maxMedium} {
    width: 200px;
  }

  img {
    display: block;
  }

  &:hover, &:focus {
    @include opacity(80);
  }
}

.main-nav {
  //text-transform: uppercase;
  @include optimize-font;
  //color: $black;
  margin-top: 16px;

  li:first-child {
    a {
      border-top: none;
    }
  }

  a {
    text-decoration: none;
    font-weight: 400;
    //color: $grayDark;
    padding: 12px 15px;
    display: block;
    border-top: 1px solid $black;
  }

  a:hover, a:focus, li.active a {
    color: $black;
  }


  @media #{$medium} {
    margin: 28px 0 0 40px;
    float: right;

    li {
      float: left;
      margin: 0 0 0 20px;
    }

    a {
      border-top: 0;
      padding: 0;
    }
  }

  @media #{$large} {
    li {
      margin-left: 35px;
    }
  }
}

.main-nav__trigger {
  display: block;
  padding: 12px 20px 12px 20px;
  color: $black;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 0px;
  right: 20px;
  //border: 1px solid #efefef;

  height: 38px;
  z-index: 99999;
  background-color: $white;

  .icon-hamburger {
    position: absolute;
    left: 10px;
    top: 10px;
  }

  &:hover, &:focus {
    background-color: transparent;
    box-shadow: none;
  }

  &:active {
    transform: none;
  }

  @media #{$medium} {
    display: none;
  }
}

.main-nav__wrap {
  display: none;
  margin: 0 0 15px;
  @include fs(14);


  @media #{$maxMedium} {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
    width: 100%;
    height: 100%;
    z-index: 999;
    padding: 20px;
    padding-top: 120px;

  }

  @media #{$medium} {
    margin: 0 0 0 -20px;
    display: block;

  }
}

.skip-link {
  left: -999px;
  position: absolute;
  top: auto;
  overflow: hidden;
  z-index: -999;
  white-space: nowrap;

  &:focus {
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #fff;
    padding: 0.5em 1em;
    @include fs(20);
  }
}

/* =============================================================================
   MAIN
   ========================================================================== */

.main {
  padding: 20px 0;

  @media #{$medium} {
    padding: 0px 0 250px;
  }
}

/* =============================================================================
   FOOTER
   ========================================================================== */
.pos-s {
  position: static;
}
.page-footer {
  position: relative;




  .grid {
    margin-bottom: 0;
  }

  .container {
    width: 100%;
  }

  @media #{$medium} {
    height: 250px;
    margin: -250px 0 0;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: 5;
    p {
      margin-bottom: 0;
    }
  }
}


.page-footer__top  {
  background-image: url("../images/paper-bg.jpg");
  background-size: cover;
  background-position: top center;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  @include fs(15);

  @media #{$medium} {

    @include fs(17);
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.page-footer__bottom  {
  background-color: $white;
  padding: 20px 0;
  @include fs(14);

  ul {
    list-style: none;
    margin-left: 0;

    @media #{$medium} {
      margin-bottom: 0;
    }
    a {
      text-decoration: underline;
    }
    li {
      display: inline-block;
      &:after {
        content: '&';
        display: inline-block;
        margin-left: 7.5px;
        margin-right: 7.5px;
      }
      &:last-child:after {
        content: none;
      }
    }
  }
}

a.esign {
  width: 80px;
  height: 20px;
  background-image: url("../images/logo-esign.svg");
  background-size: 77px 20px;
  background-repeat: no-repeat;
  background-position: top left;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  text-indent: -9999px;
  opacity: 0.6;
  transition-duration: 0.5s;
  display: block;
  outline: 0;
  z-index: 9999;
  &:hover, &:focus {
    opacity: 1;
  }
  @media #{$medium} {
    width: 16px;
    position: absolute;
    right: 45px;
    bottom: 0;
    margin-bottom: 10px;
    &:hover, &:focus {
      opacity: 1;
      width: 80px;
    }
  }
}

/* =============================================================================
   COMPONENTS
   ========================================================================== */

// Rte
.large {
  @include fs(20);
}

.center-block {
  @include clearfix;
  text-align: center;
  margin: 20px 0;

  .button {
    float: none;
    display: inline-block;
  }
}

// Article
section {
  position: relative;
}

article {
  @include clearfix;
  margin: 0 0 20px;

  a {
    img {
      display: block;
      @include opacity(100);
    }

    &:hover, &:focus {
      img {
        @include opacity(80);
      }
    }
  }
}

.article-detail {

}

.article-detail__image {
  margin-bottom: 1.5em;
}

// Note
.note {
  @include clearfix;
  margin: 0 0 20px;
  padding: 15px 20px 0;
  background: #efefef;

  ul, p {
    margin-bottom: 1em;
  }
}

.note--success {
  color: $success;
  background-color: $successBg;
}

.note--error {
  color: $error;
  background-color: $errorBg;
}

// Slideshow
.cycle-slideshow {
  width: 100%;
  position: relative;
}

.cycle-pager {
  width: 100%;
  text-align: center;

  span {
    display: inline-block;
    margin: 0 3px;
    font-size: 36px;
    height: 20px;
    width: 20px;
    line-height: 14px;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 20px;
    color: #fff;
    overflow: hidden;
    text-indent: -500px;
    transition: $transitionDefault;

    &:hover, &:focus {
      transition: none;
    }
  }

  span:hover, span:focus, .cycle-pager-active {
    text-indent: 0;
  }
}

// Map
.map {
  width: 100%;
  height: 350px;
  background: #efefef;
  margin: 0 0 40px;

  @media #{$medium} {
    height: 550px;
  }
}

// Social
.social {
  @include clearfix;
  list-style: none;
  margin: 0 0 2em -5px;
}

.social__item {
  padding: 0 0 5px 5px;
  float: left;
}

.social__link {
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: block;
  border-radius: 40px;
  background-color: $accent;
  text-decoration: none;
  text-align: center;
  overflow: hidden;
}

.social__link--facebook {
  background-color: #3C5B9B;
  &:hover, &:focus {
    background-color: darken(#3C5B9B, 8%);
  }
}

.social__link--twitter {
  background-color: #2DAAE1;
  &:hover, &:focus {
    background-color: darken(#2DAAE1, 8%);
  }
}

.social__link--pinterest {
  background-color: #CB2027;
  &:hover, &:focus {
    background-color: darken(#CB2027, 8%);
  }
}

.social__link--linkedin {
  background-color: #0173B2;
  &:hover, &:focus {
    background-color: darken(#0173B2, 8%);
  }
}

.visual-bg {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;

  img {
    visibility: hidden;
    width: 100%;
    @media #{$medium} {

    }
  }
}

.visual-bg--high {
  @media #{$medium} {
    height: 550px;
  }
}
.visual-bg--500 {
  @media #{$medium} {
    height: 500px;
  }

}

@media #{$medium} {
  .flex-a-c {
    align-items: center;
  }
  .flex-a-fe {
    align-items: flex-end;
  }
  .flex-switch-order {
    .grid__item:first-child {
      order: 1;
    }
    .grid__item:nth-child(2) {
      order: -1;
    }
  }
}

.pl-left-gutter {
  padding-left: 20px;

  @media #{$medium} {
  padding-left: 40px;
}
}

.left-border-box {

  border-left: 1px solid $grayMiddle;

}


.highlighted-paragraph {

  font-weight: 600;
  @include display-font;
  @include fs(18);
  letter-spacing: 0.025em;

  p {
    margin-bottom: 0;
  }
  margin-bottom: $gutter*2;

}

.container--o-h {
  overflow: hidden;
}

.container--paper-banner {
  position: relative;
  overflow: visible;
  padding: 0;
  left: 0;
  //right: 0;
  top: 0;
  //margin-right: 10px;
  height: 50px;
  /*margin-bottom: -40px;*/

  @media #{$medium} {
    height: 100px;
  }

  @media #{$larger} {
    height: 150px;
  }

  * {
    height: 100%;
  }
}

.paper-banner {
  background: url('../images/paper-bg.jpg');
  background-size: cover;
  background-position: top center;
  width: 100%;
  position: absolute;
  left: -30px;
  top: 0;
  margin-left: $gutter*6;
  //left: 25%;

  @media #{$medium} {
    left: -50px;
  }
}

.pull-top {
  margin-top: -15px;
  @media #{$medium} {

    margin-top: -20px;
  }

  @media #{$larger} {

    margin-top: -30px;
  }
}

.pull-top--large {
  @media #{$medium} {

    margin-top: -40px;
  }

  @media #{$larger} {

    margin-top: -150px;
  }
}

.padding-t-b {
  @media #{$medium} {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  @media #{$larger} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

//
/* =============================================================================
   PAGES
   ========================================================================== */

/* =================================================
   HOME
   ================================================= */
.header {
  position: relative;
  margin-bottom: $gutter;
}

.header--home {
  @media #{$medium} {
    margin-bottom: 60px;

  }
  @media #{$larger} {
    margin-bottom: 155px;

  }
}

.header__visual {
  width: 100%;
  margin-bottom: $gutter;
 img {

 }
  @media #{$medium} {
    height: 550px;
    margin-bottom: 0;
  }
}

.header__content {
  @media #{$medium} {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 70px 30px;

    h1 {
      color: $white;
      margin-bottom: 0;
    }

  }

}

/* =================================================
   specialaisaties
   ================================================= */
.display-list {
  @include display-font;
  letter-spacing: 0.05em;
  list-style: none;
  margin-left: 0;
  margin-bottom: 0;
  li {

    margin-bottom: 10px;

    @media #{$medium} {

      margin-bottom: 5px;
    }
  }
}

/* =================================================
   About
   ================================================= */

.team {

  position: relative;

  &:after {
    @media #{$medium} {
      content: '';
    }

    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: -55px;
    height: 150px;
    width: calc(75% - 120px);
    background-image: url('../images/paper-bg.jpg');
    background-position: top center;
    background-size: cover;
    z-index: -1;
    max-width: calc(1520px - 25%);


  }

}
.team-member {
  position: relative;
  width: 100%;
  margin-bottom: $gutter*2;
  overflow: hidden;

  @media #{$medium} {
    margin-bottom: 0;
    &:hover, &:focus, &:active {
      .team-member__content {
        padding-top: 20px;
        top: 0;
        margin-top: 0;
        background: rgba(0,0,0,0.85);
      }

    }
  }
  @media #{$huge} {
    &:hover, &:focus, &:active {
      .team-member__content {
        padding-top: 40px;
      }
    }
  }
}

.team-member__name {
  @extend .h3;
  margin-bottom: $gutter;

  @media #{$medium} {

    color: $white;
    margin-bottom: $gutter;

  }
  @media #{$huge} {
    margin-bottom: $gutter*2;

  }

}

.team-member__visual {
  width: 100%;
  height: 550px;
  transition: $transitionDefault;
  margin-bottom: $gutter;
  @media #{$medium} {
    margin-bottom: 0;
  }

}

.team-member__content {

  @include fs(14);

 /* @media #{$large} {
    @include fs(12);
  }*/
  @media #{$huge} {
    @include fs(14);
  }


  ul {
    list-style: none;
    li {
      position: relative;
      padding-left: 10px;
      &:before {
        content: '-';
        position: absolute;
        left: 0;
        margin-left: -10px;

      }
    }

  }

  a {
    font-weight: 600;
    text-decoration: underline;
  }


  @media #{$medium} {
    padding: 20px;
    padding-top: 15px;
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: -50px;
    width: 100%;
    height: 100%;
    transition: $transitionDefault;
    transition-duration: 0.4s;
    color: $white;
    background: rgba(0,0,0,1);

    a {
      color: $white;
    }

  }

 /* @media #{$large} {
   // padding: 40px;
   // padding-top: 15px;
    margin-top: -75px;
  }*/
  @media #{$huge} {
   // padding: 40px;
    padding-top: 15px;
    margin-top: -75px;
  }
  @media #{$massive} {
    margin-top: -50px;
  }
}


/* =============================================================================
   PRINT
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
@media print {
  @page {
    margin: 2cm 0.5cm 1cm;
  }

  html {
    font-size: 10px;
  }

  * {
    background-color: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  .ir {
    text-indent: 0;
    overflow: visible;
    direction: inherit;
  }

  a, a:visited {
    text-decoration: underline;
  }

  tr, img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }

  .page-footer, .main-nav {
    display: none;
  }

  .medium--one-half {
    width: 50%;
  }
  .medium--two-thirds {
    width: 66.66%;
  }
  .medium--one-third {
    width: 33.33%;
  }
  .medium--three-quarters {
    width: 75%;
  }
  .medium--one-quarter {
    width: 25%;
  }
}
